import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const MenuFR = () => {
  return (
    <Layout>
      <SEO title="Meni Konoba Fakin" lang="fr" />
      <div className="content menu-page">
        <div className="naslov-container">
          <h1>Menu</h1>
          <div>
            <Link to="/menu">HR</Link>
            <Link to="/menu-en">EN</Link>
            <Link to="/menu-it">IT</Link>
            <Link to="/menu-de">DE</Link>
            <Link to="/menu-rus">RUS</Link>
          </div>
        </div>
        <h3>Entrées froides</h3>
        <ul>
          <li>Crème de truffe avec fromage à la truffe</li>
          <li>
            Salade de crevettes, avec fromage de chèvre et concombres frais
          </li>
          <li>Tartare de filet de veau avec aïoli et bruschetta</li>
          <li>Vitello tonnato</li>
          <li>Pâté de foie aux truffes et saucisson d'Istrie</li>
          <li>Jambon cru de la production locale avec olives</li>
          <li>
            Plat Fakin pour 2 personnes (jambon cru, saucissons, fromage de la
            production locale et fromage à la truffe)
          </li>
        </ul>
        <h3>Potages</h3>
        <ul>
          <li>Soupe aux truffes noires</li>
          <li>Velouté d'asperges</li>
          <li>Soupe d'Istrie</li>
        </ul>
        <h3>Entrées chaudes</h3>
        <ul>
          <li>Pâtes aux truffes noires</li>
          <li>Omelette aux truffes noires</li>
          <li>Spaghetti aux crevettes, moules et tomates cerises</li>
          <li>Paccheri à la Bolognaise avec raifort frais</li>
          <li>
            Gnocchi à la romaine sauce aux truffes avec chips de pancetta
            d'Istrie
          </li>
          <li>Goulasch de veau avec gnocchi fait maison</li>
        </ul>
        <h3>Seconds plats</h3>
        <ul>
          <li>
            Filet de loup de mer (bar commun) gratiné aux herbes aromatiques,
            avec ratatouille et purée de pommes de terre à la ciboulette
          </li>
          <li>
            Filet de poulet farci au fromage et cuit au four sur épinards et
            champignons
          </li>
          <li>
            Côtelette d'agneau à la sauce au thym, pommes de terre au four au
            romarin et Julienne de légumes
          </li>
          <li>Bifteck à la truffe noire sur purée de pommes de terre</li>
          <li>
            Bifteck à la sauce de Terran avec purée de céleri et salade mixte
          </li>
          <li>
            Filet de porc local à la truffe noire avec pommes de terre au four
          </li>
          <li>Légumes grillés</li>
          <li>Ragoût de gibier avec polenta cuite au four</li>
        </ul>
        <h3>Desserts</h3>
        <ul>
          <li>Soufflé au chocolat</li>
          <li>Truffe au chocolat</li>
          <li>Pralines de chocolat aux truffes</li>
          <li>Crêpes avec crème à l'orange et Cointreau</li>
          <li>Crème brûlée</li>
          <li>
            Plateau de fromages d'Istrie avec huile d'olive, miel et amandes
          </li>
        </ul>
        <h3>Salades</h3>
        <ul>
          <li>Salade avec truffes</li>
          <li>Sélection journalière de salades de saison</li>
        </ul>
      </div>
    </Layout>
  )
}

export default MenuFR
